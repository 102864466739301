import { render, staticRenderFns } from "./QuatationView.vue?vue&type=template&id=0b9b2c80&scoped=true&"
import script from "./QuatationView.vue?vue&type=script&lang=js&"
export * from "./QuatationView.vue?vue&type=script&lang=js&"
import style0 from "./QuatationView.vue?vue&type=style&index=0&id=0b9b2c80&prod&lang=scss&scoped=true&"
import style1 from "./QuatationView.vue?vue&type=style&index=1&id=0b9b2c80&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b9b2c80",
  null
  
)

export default component.exports