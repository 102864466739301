<template>
  <section class="invoice-preview-wrapper">

    <b-row
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          id="quotation"
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body  class="invoice-padding pb-0">

            <div  class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div class="">
                <div class="logo-wrapper">
                  <logo />
                  <h3 class="text-primary">
                    冰山數據
                  </h3>
                </div>
                <p class="card-text mb-25">
                  甲方：{{ projectData.company_name }}
                </p>
                <p class="card-text mb-25">
                  乙方：玉案（北京）软件有限公司
                </p>
                <p class="card-text mb-25">
                  项目名称：{{ projectData.project_name }}
                </p>
                <p class="card-text mb-25">
                  项目编号：{{ projectData.project_id }}
                </p>
                

              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  报价单
                  <span class="invoice-number">{{  }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    报价日期：
                  </p>
                  <p class="invoice-date">
                    {{ dateDefault }}
                  </p>

                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    商务经理：
                  </p>
                  <p class="invoice-date">
                    {{ bmName }}
                  </p>
                </div>

                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    邮箱：
                  </p>
                  <p class="invoice-date">
                    {{ bmEmail }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->

          <h6 class="text-center">准确率标准</h6>
          <b-table-lite
            :bordered="true"
            :striped="true"
            responsive
            :items="rateData"
            :fields="['元素', '准确率标准（%）', '备注']"
          >
            <template #cell(item)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data }}
              </b-card-text>
            </template>
          </b-table-lite>
          <!-- Invoice Description: Table -->
          <h6 class="text-center">报价</h6>
          <b-table-lite
            :bordered="true"
            :striped="true"
            responsive
            :items="priceData"
            :fields="['元素单位', '单价（元）', '备注']"
          >
            <template #cell(item)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data }}
              </b-card-text>
              <b-card-text class="text-nowrap">
                {{  }}
              </b-card-text>
            </template>
          </b-table-lite>


          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text v-if="otherDescription.length" class="mb-0">
                  <span class="font-weight-bold">其他说明:</span>
                  <span class="ml-75">{{ otherDescription }}</span>
                </b-card-text>
              </b-col>
              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  
                
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      含税:
                    </p>
                    <p class="invoice-total-amount">
                      1%
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      币种:
                    </p>
                    <p class="invoice-total-amount">
                      ￥人民币元
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">注意: </span>
            <span>甲乙双方应遵守商业保密义务，除双方企业内部相关人员外，请勿向任何第三方泄露该文档，感谢理解！</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="saveQuotation()"
            :disabled="isSaving"
          >
            保存
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="not-print-area">
      <b-col cols="12" xl="9" md="8">
        <b-card title="编辑">
          <b-card-body>
            <b-row>
              <b-col>
                <b-form-group
                  label="报价日期"
                  label-for="quoteDate"
                >
                <flat-pickr
                  v-model="dateDefault"
                  class="form-control"
                />
                </b-form-group>
              </b-col>

              <b-col>
              </b-col>
              <b-col>
              </b-col>
            </b-row>

            <div>
            <label for="textarea-default">其他说明</label>
            <b-form-textarea
              v-model="otherDescription"
              id="textarea-default"
              placeholder=""
              rows="3"
            />
          </div>
           
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>

import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,BFormGroup,BFormInput,BFormTextarea
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    Logo,
    flatPickr,
  },
  data(){
    return{
      bmName: JSON.parse(localStorage.getItem('userData')).fullName,
      bmEmail: JSON.parse(localStorage.getItem('userData')).email,
      dateDefault: null,
      otherDescription: '',
      priceData:[],
      rateData:[],
      projectData:{},
      priceId: '',
      isSaving: false,
    }
  },
  methods: {
    saveQuotation(){
      var postData = {
        "priceId": this.priceId,
        "customerCompanyId": this.projectData.company,
        "customerCompanyName": this.projectData.company_name,
        "projectName": this.projectData.project_name,
        "projectId": this.projectData.project_id,
        "quotationDate":this.dateDefault,
        "bmName": this.bmName,
        "bmEmail": this.bmEmail,
        "priceData": this.priceData,
        "rateData": this.rateData,
        "otherDescription": this.otherDescription
      }
      this.isSaving = true
      this.$axios.post('/collab/pm/gen_quotation', postData).then(res => {
        this.isSaving = false;
        if (res.data.status == "ok"){
              const url = this.$backend + '/download_by_token/' + res.data.data.token
              const link = document.createElement('a')
              link.setAttribute('href', url)
              link.setAttribute('download', "QUOTATION_" + this.$route.params.id + '.pdf')
              document.body.appendChild(link)
              link.click()
              link.remove()
          }
      })
    },
    printQuotation(){
      var rawTitle = document.title
      document.title = "QUOTATION_" + this.$route.params.id
      window.print()
      document.title = rawTitle
    },
    getPriceData(){
      this.$axios.get('/collab/pm/get_price_tables/' + this.$route.params.id).then(res => {
        this.priceId = res.data.data.price_id
        let table = (res.data.data.tables.newest_table.price_table)
        table.forEach(row => {
          this.priceData.push({
            "元素单位": row.element,
            "单价（元）": row.price,
            "备注": row.note,
          })
        })
        let rateTable = (res.data.data.tables.newest_table.rate_table)
        rateTable.forEach(row => {
          this.rateData.push({
            "元素": row.element,
            "准确率标准（%）": row.rate,
            "备注": row.note,
          })
        })
        ;
      })
    },
    getProjectData(){
      this.$axios.get('/collab/pm/get_project/' + this.$route.params.id).then(res => {
        this.projectData = res.data.data.project
      })
    },
    getUsers(){
      this.$axios.get('/manage/users/get_users').then(res => {
        this.users = res.data.data.users
        let loginUserId = JSON.parse(localStorage.getItem('userData')).userName
        this.users.forEach(user => {
          if(user.id == loginUserId){
            //this.bmName = user
            return true
          }
        })
      })
    },
  },
  created(){
    this.getPriceData()
    this.getProjectData()
    this.getUsers()
    let date = new Date()
    this.dateDefault = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">

@media print {
  @page { margin: 0; size: landscape;}
  html{
    height:auto
  }
  // Global Styles
  body {
    background-color: transparent !important;
    height: auto;  
  }

  .not-print-area{
    display: none;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
